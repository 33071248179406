




































import Vue from 'vue'
import { ApiResponse, Model } from '@/interfaces'
import { mapState } from 'vuex'

export default Vue.extend({
  props: {
    toggleModal: {
      type: Boolean,
      default: false,
    },
    contactId: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },
    chosenStructure: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      chosenModel: null as number | null,
      //chosenStructure: null as number | null,
      customText: null as string | null,
      structures: [],
      busy: false,
      models: [] as Model[],
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    toggleModal(newVal) {
      this.showModal = true
    },
    user(newVal) {
      if (newVal) {
        this.structures = newVal.client.structures
      }
    },
  },
  mounted() {
    //this.getTemplates()
    if (this.user) {
      this.structures = this.user.client.structures
    }
  },
  methods: {
    generateBoughtItemsDocument() {
      if (!this.busy) {
        this.busy = true
        this.$api
          .post(`/document/${this.contactId}/contact`, {
            chosenModel: this.chosenModel,
            chosenStructure: this.chosenStructure,
            ids: this.items,
            customText: this.customText,
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            this.download(apiResponse.data.format, apiResponse.data.accessToken)
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
            this.showModal = false
          })
      }
    },
    getTemplates() {
      if (this.models.length == 0) {
        this.$api.get(`/model/contact/list`).then((response) => {
          const apiResponse = response.data as ApiResponse
          this.models = apiResponse.data
        })
      }
    },
    download(format: string, token: string) {
      let url = ''
      if (format == 'pdf') {
        url = `${process.env.VUE_APP_API_URL}/document/${token}/download?contentd=inline`
      } else {
        url = `${process.env.VUE_APP_API_URL}/document/${token}/download`
      }
      window.open(url, '_blank')
    },
  },
})
