































































































































import Vue from 'vue'
import { ApiResponse, RequisitionItem } from '@/interfaces'

export default Vue.extend({
  props: {
    toggleModal: {
      type: Boolean,
      default: false,
    },
    invoiceId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      requisitionItems: [] as RequisitionItem[],
      unpaidRequisitionItems: [] as RequisitionItem[],
      stats: {
        countItems: 0,
        countUnpaidItems: 0,
        type: null,
        countInvoiceItems: 0,
        totalAdj: 0,
        priceIncludingTaxes: 0,
        remainingPayment: 0,
        averageFees: 0,
        payable: 0
      },
    }
  },
  watch: {
    toggleModal(newVal) {
      this.showModal = true
      //this.fetchRequisitionItems()
      //this.requisitionItems = {} as RequisitionItem
    },
    invoiceId(newVal) {
      this.fetchRequisitionItems()
    },
  },
  mounted() {
    //this.fetchRequisitionItems()
  },
  methods: {
    fetchRequisitionItems() {
      if (this.invoiceId) {
        this.$api
          .get(`/invoice/${this.invoiceId}/unpaid-requisitionitems`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            this.unpaidRequisitionItems = apiResponse.data.unpaidItems
            this.requisitionItems = apiResponse.data.items
            this.stats = apiResponse.data.stats
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
      }
    },
    formattedItemDescription(description: string) {
      if (description) {
        if (description.length > 50) {
          return description.substring(0, 50) + '...'
        } else {
          return description
        }
      }
      return '-'
    },
    editRequisitionItem(item: RequisitionItem) {
      this.$router.push({
        name: 'requisition_item_edit',
        params: {
          itemId: item.id?.toString() ?? '',
        },
        query: {
          backTo: this.$route.name,
          backScrollTo: window.scrollY.toString(),
        },
      })
    },
    displayProgress() {
      if (this.stats.countUnpaidItems) {
        return `${this.stats.countItems - this.stats.countUnpaidItems} / ${this.stats.countItems}`
      } else {
        return ''
      }
    },
    getProgress(): number {
      if (this.stats.countUnpaidItems) {
        return (100 * (this.stats.countItems - this.stats.countUnpaidItems)) / this.stats.countItems
      } else {
        return 0
      }
    },
  },
})
